/**
 * Define constant values which you MUST change
 * depending on the runnning environment
 *
 */
export default {
  server: 'https://aiquick.mori.dev.dialogplay.jp/api',
  messagingServer: 'https://aiquick.mori.dev.dialogplay.jp/api',
  voiceSynthServer: 'https://voicevox.dev.dialogplay.jp',
  voiceSynthAccessToken: '8F9dJnGMYpc4RjZ7',
  emailSender: 'no-reply <no-reply@dev.dialogplay.jp>',
  awsIotEndpoint: 'aaa9rccpxowt-ats.iot.ap-northeast-1.amazonaws.com',
  IdentityPoolId: 'ap-northeast-1:39900eea-d15a-4bd2-87db-fdbe4c8bdf2b',
  paymentStoreId: '113682',
  importantMessagesURLs: {
    json: '/important.json',
  },
  embeddedChatURL: 'https://aiquick.mori.dev.dialogplay.jp/embedded',
  redirectURL: 'https://aiquick.mori.dev.dialogplay.jp/api/redirect',
  // toolDirectoryServer: 'https://tool-directory.dialogplay.jp'
  toolDirectoryServer: 'https://tool-directory.dev.dialogplay.jp',
  // toolDirectoryServer: 'https://aiquick.mori.dev.dialogplay.jp/static-files/tool-directory',
  isExperimental: false,
  enableExport: true,
}
